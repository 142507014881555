export default {
  meta_title: 'Frequently Asked Questions (FAQs) | PennyLane',
  meta_description:
    'Get quick answers to your most burning questions on PennyLane and quantum machine learning.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/generic/thumbnail.png',
  title: 'Frequently Asked Questions',
  sub_title: 'Get **quick answers** to your most burning questions.',
  questions_answers: [
    {
      title: 'What is PennyLane?',
      description:
        'PennyLane is a **software framework for differentiable quantum programming**, similar to TensorFlow and PyTorch for classical computation. It facilitates the training of variational quantum circuits.',
    },
    {
      title: 'Does PennyLane work with hardware?',
      description:
        'Yes, PennyLane can be used to optimize quantum circuits running on hardware. Simply choose a hardware backend as your device. You can find all available backends in the [plugins section](/plugins).',
    },
    {
      title: 'Can I use PennyLane with PyTorch/TensorFlow?',
      description:
        'Yes, PennyLane integrates with PyTorch and TensorFlow. More information can be found in the [documentation](https://pennylane.readthedocs.io).',
    },
    {
      title:
        'What distinguishes PennyLane from other quantum programming languages?',
      description:
        'While offering a lot of the functionality of standard quantum programming languages, PennyLane is built around the idea of **training quantum circuits using automatic differentiation**. This is especially important in applications such as quantum machine learning, quantum chemistry, and quantum optimization.',
    },
    {
      title: 'What is quantum machine learning?',
      description: `
Quantum machine learning investigates the **consequences of using quantum computers for machine learning**, by extending the pool of hardware for machine learning by an entirely new type of computing device—the quantum computer.

One can understand these devices as a form of special-purpose hardware like Application-Specific Integrated Circuits (ASICs) and Field-Programmable Gate Arrays (FPGAs), as they are limited in the number and type of operations that can be executed in a single run. However, information processing with quantum computers relies on substantially different laws of physics compared to ASICs and FPGAs.

In modern quantum machine learning, near-term quantum devices are used and trained like neural networks, using **variational quantum circuits**. More information can be found in our [What is QML?](/qml/whatisqml) page.`,
    },
    {
      title: 'What are variational circuits?',
      description: `Variational quantum circuits, also called parametrized quantum circuits, are **quantum algorithms that depend on tunable parameters**.

For example consider a quantum algorithm where one operation rotates a qubit by a certain angle kept as a free parameter. The result of the quantum computation now depends on the chosen angle. Using a classical co-processor, the angle, and thereby the quantum circuit, can be **optimized for a given task**.

The principle of variational circuits is very similar to neural networks, which is why they play an important role in quantum machine learning.

Visit our [QML glossary](/qml/glossary) for more information on the key concepts underpinning quantum machine learning.`,
    },
    {
      title: 'How does PennyLane evaluate gradients of quantum circuits?',
      description: `Wherever possible, **PennyLane uses parameter-shift rules** to extract gradients of quantum circuits. These rules prescribe how to estimate a gradient by running a circuit twice or more times with deliberately shifted parameters.

In situations where no parameter-shift rule can be applied, PennyLane uses the finite-difference rule to approximate a gradient.

Both options work whether you run your code on simulators or an actual quantum device.

Visit our [QML glossary](/qml/glossary) for more information on the key concepts underpinning quantum machine learning.`,
    },
    {
      title: 'Is PennyLane open source?',
      description:
        'Yes, PennyLane is open source software developed under the Apache 2.0 License.',
    },
  ],
}
